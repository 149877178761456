<template>
  <div class="agrementDetail">
    <div class="agrement-content">
      <li class="main-title">隐私保护政策</li>
      <li>
        您的个人信息安全对我们来说至关重要。我们将依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》（GB/T
        35273-2017）以及其他相关法律法规和技术规范收集和使用您的个人信息，以帮助我们向您提供更优质的产品和/或服务。我们承诺会对您的个人信息和其它数据进行严格保密，并严格按照本政策所阐述的内容处理您的个人信息。我们会根据您的同意和其它可处理您个人信息的法律依据收集、使用、存储、共享和转移您的个人信息。
      </li>
      <li>以下是《隐私保护政策》的要点说明：</li>
      <li>
        1.
        为帮助您使用我们的相关产品和服务，我们可能收集与提供服务相关的个人信息，您有权拒绝或撤回授权；
      </li>
      <li>
        2.
        我们采取了互联网业内标准的技术措施和数据安全措施来保护您的个人信息安全。
      </li>
      <li>
        3.
        除非再次征得您的同意，我们不会将您的个人信息用于本政策未载明的其他目的。
      </li>
      <li>4. 当您对本政策有任何疑问，可以和我们进一步联系与咨询。</li>
    </div>
    <div class="agrement-content">
      <li class="main-title" style="margin-bottom:0;">肿瘤基因检测知情同意书</li>
      <li class="main-title">Informed Consent</li>
      <li>
        &nbsp;&nbsp;&nbsp;&nbsp;肿瘤细胞内不同基因突变的排列组合使得患者对于抗肿瘤药物的反应千差万别。裕康医学推出的肿瘤基因检测产品，进行全面的基因突变检测及肿瘤分子分型，可以协助医生和患者选择最合适的药物、寻找最有效的治疗方案，实现精准治疗。在检测程序开始之前，请您仔细阅读以下条款。
      </li>
      <li class="second-title">【检测事项】</li>
      <li>
        1. 样本类型：患者提供肿瘤组织及对照样本，具体样本信息见检测申请单。
      </li>
      <li>
        2.
        检测内容：通过高通量基因测序（NGS）技术对癌症相关突变基因进行检测。测序或其他检测结果通过生物信息学分析和临床相关性解读。
      </li>
      <li>
        3.
        检测周期：实验室收到样本后至出具检测报告的时间，具体时间见检测申请单。若出现不可抗力因素（如自然灾害、恶劣天气、政府行为、社会异常事件等）交付周期顺延。若出现意外（如采血管破裂）导致样本损耗，患者应配合检测机构再次取样。同时检测周期需从收到新样本之日起重新计算。
      </li>
      <li>
        4.
        结果建议：检测结果以报告（纸质或电子版）的形式告知主治医师和患者。本检测结果为患者提供可选择的治疗参考信息，但不对患者治疗方法选择、治疗效果预期等方面起决定性作用。具体治疗方案需由临床医师根据基因检测结果及其他临床信息来选择。
      </li>
      <li class="second-title">【技术局限性及潜在风险】</li>
      <li>
        1.
        鉴于当前医学检测技术水平的限制和受检者个体差异及样本差异等原因，即使在检测人员已经认真履行了工作职责和操作程序的前提下，仍有可能出现假阳性或假阴性。
      </li>
      <li>
        2.
        本检测项目并非检测肿瘤细胞的所有基因，只适用于检测肿瘤特定部分基因的突变。
      </li>
      <li>
        3.
        本次检测规定该产品检测范围外的发现为次要发现，本实验室对于次要发现不进行报告。
      </li>
      <li>
        4.
        随着时间推移，研究证据的积累，变异的分类或解释可能有所变化，新的研究进展可能对受检者的检测结果产生新的解读信息。
      </li>
      <li>
        5.
        在检测过程中及知晓检测结果后，因自身心理或生理因素可能引起受检者出现不同程度的精神压力和负担，由此产生的次生危害，检测机构不承担任何责任。
      </li>
      <li>
        6.
        我们将妥善保存与受检者个人身份有关的资料，仅在特殊情况下供政府管理部门或相关生命伦理和生物安全审查委员会成员查阅。检测结果将会用于进一步科学研究,但不会透露任何可识别受检者身份的信息。
      </li>
      <li>
        深圳裕康医学检验实验室或其委托的第三方对检测涉及的样本有权进行安全处置，包括血液、血浆和医疗废物。
      </li>
      <li class="second-title">受检者确认</li>
      <li>
        1. 我知情将由深圳裕康医学检验实验室进行该项基因检测，其将在收到样本
        14个自然日内交付报告。
      </li>
      <li>
        2.
        我已充分了解该检测的性质、预期目的、检测的风险和必要性，对其产生疑问已经得到医师解答，自愿同意进行此项检测。
      </li>
      <li>
        3.
        我知晓且同意样本采集及运输过程中可能导致的破损、污染等情形需重新检测，我同意配合重新检测事宜。
      </li>
      <li>
        受检人陈述：本人承诺提供的个人信息真实有效，我认真阅读知情同意书，并已知晓肿瘤基因检测可能存在的局限性和风险，自愿参加该项检测，同意回访并承担该项检测带来的风险。
      </li>
    </div>
  </div>
</template>
<script>
export default {
  name: "AgrementDetail",
};
</script>
<style lang="less" scoped>
.agrementDetail {
  padding: 32px;
  font-size: @font-size-medium;
  color: @color-26;
  line-height: 1.5;
  .agrement-content {
    li {
      margin-bottom: 20px;
    }
    li.main-title {
      font-size: @font-size-large-x;
      text-align: center;
      font-weight: bold;
    }
    .second-title{
      font-weight: bold;
    }
  }
}
</style>